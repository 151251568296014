<template>
  <div style="background: #f6f6f6;color: #000;">
    <van-nav-bar title="" left-arrow :fixed="true" :z-index="999" @click-left="back">
      <template #title>
        {{ source }}(<span :style="{ color: (names == '未结' ? 'red' : '#007832') }">{{ names }}</span>)业务列表
      </template>
      <template #right>
        <van-icon @click="shuaxin" name="replay" size="18px" />
      </template>
    </van-nav-bar>
    <div class="tops">

    </div>
    <div class="nav-bar">
      <!-- <div class="flisx">
        <div style="flex: 1;justify-content: right;margin-left: 10px;font-size: 14px;">
          <van-checkbox v-if="names == '未结'" @change="onSearchqx" v-model="checkedqx" checked-color="#007832"
            shape="square">全选</van-checkbox>
        </div>
        <div style="flex: 1;">
          <van-icon @click="timeshang" name="play" style="transform:rotate(180deg);" />
          {{ time }}
          <van-icon @click="timexia" name="play" />
        </div>
        <div style="flex: 1;display: flex;justify-content: right;margin-right: 10px;font-size: 14px;">
          <van-checkbox @change="onSearch" v-model="checked" checked-color="#007832" shape="square">查看全部</van-checkbox>
        </div>
      </div> -->

      <div class="ywdiv">
        <div v-if="type == 0" style="flex: 1;">
          业务员：
          <span style="color: #666;">{{ sellsMan ? sellsMan : '暂无' }}</span>
        </div>
        <div style="flex: 1;display: flex;justify-content: flex-end;">
          <van-checkbox icon-size="18px" checked-color="#0d906e" @change="onSearch" shape="square"
            v-model="typeA">3日内</van-checkbox>
          <van-checkbox icon-size="18px" checked-color="#0d906e" @change="onSearch" shape="square"
            style="margin-left: 6px;" v-model="typeB">3日以上</van-checkbox>
        </div>
        <!-- <van-checkbox v-if="names == '未结'" @change="onSearchqx" v-model="checkedqx" checked-color="#007832"
          shape="square">全选</van-checkbox> -->
      </div>
    </div>

    <div style="padding-top: 46px;">

      <van-list v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多了" @load="onLoads"
        :offset="10">
        <div class="mian" v-for="(item, idx) in list" :key="idx">
          <!-- @click="tourl(item)" -->
          <div class="zuolit">
            <van-row class="xmdiv">
              <van-col span="12" class="flx">
                <div v-if="names == '未结'">
                  <van-checkbox v-if="!item.accountNumber && (item.fdresult == 0 || item.fdresult == -1)" class="xzs"
                    checked-color="#007832" v-model="item.checked" shape="square" @change="changexuanze"></van-checkbox>
                </div>

                <div class="carNo" @click="toapp(item)">{{ item.carNo }}</div>
                <!-- <img class="fximg" @click="tourss(item)" src="../../../assets/img/fximg.png" alt=""> -->
              </van-col>
              <van-col span="6" class="flx" style="font-size: 18px;">
                <div><span style="color: #ff1d1d;" v-if="!item.accident">￥{{ item.amount }}</span> <span
                    style="color: #00973a;" v-else>-{{ item.fd }}</span></div>
              </van-col>
              <van-col span="6" class="ris" @click="toapp(item)">
                <span v-if="item.days" style="margin-right: 2px;">{{ item.days }}天</span>
                <span v-if="item.type == 0" class="jsname" :class="names == '未结' ? 'wj' : 'yj'">
                  {{ names }}
                </span>
                <span v-else class="gz">挂账</span>
              </van-col>
            </van-row>
            <van-row class="xmdiv">
              <van-col span="12">车型：{{ item.carModel ? item.carModel : '暂无车型' }}</van-col>
              <van-col span="12" style="text-align: right;">
                <div class="flxj">{{ item.source }}</div>
              </van-col>
            </van-row>
            <van-row class="xmdiv" v-if="!item.accident">
              <van-col span="12" style="color: #999;">施工项目 </van-col>
              <van-col span="12" style="color: #999;text-align: right;">共 <span style="color: #000;">{{ item.counts
              }}</span> 项 </van-col>
            </van-row>
            <van-row class="xmdiv" v-else>
              <van-col span="24" style="color: #c68d11;">
                事故返点
                <div class="flxj" v-if="item.accidenType == 0">未出价</div>
                <div class="flxj" style="background-color: #f7e2e2;color: #f84949;" v-if="item.accidenType == 1">未到账</div>
                <div class="flxj" style="background-color: #e2f7e4;color: #16b766;" v-if="item.accidenType == 2">已到账</div>
                <div class="flxj" @click="tozhichi(item)"
                  style="background-color: #dcf0f9;color: #038ac4;margin-left: 5px;" v-if="item.fdresult == 0">待返点</div>
                <div class="flxj" style="background-color: #e1fcee;color: #02994b;margin-left: 5px;"
                  v-if="item.fdresult == 1">已返点</div>
              </van-col>
            </van-row>

            <van-row class="xmdiv">
              <van-col span="20">{{ item.item }}<span v-if="item.accident">={{ item.fd }}</span></van-col>
              <van-col span="4" style="text-align: right;" @click="zsclick(item)" v-if="item.accident">

                <span class="zdbj" v-if="item.fdType == 1">手动</span>
                <span class="zdbj" v-else>自动</span>
              </van-col>
            </van-row>
            <van-row class="xmdiv sxtc">
              <van-col span="24">事项提醒：<span class="span">{{ item.description }}</span></van-col>
            </van-row>
            <van-row class="xmdiv" style="color: #999;">
              <van-col span="12">接车日期：{{ item.createdDate ? item.createdDate.substring(5, 16) : '' }}</van-col>
              <van-col span="12" class="ris">交车日期：{{ item.factoryOut ? item.factoryOut.substring(5, 16) : ''
              }}</van-col>
            </van-row>
            <van-row class="xmdiv" v-if="item.accountNumber" style="color: #999;border-top: 1px dashed #dcdcdc;">
              <van-col span="12">已出账单号：</van-col>
              <van-col span="12" class="ris" @click="toxqs(item)">{{ item.accountNumber }}</van-col>
            </van-row>
          </div>
        </div>
      </van-list>
    </div>

    <van-empty v-if="list.length < 1" description="暂无数据~" />
    <div style="height: 60px;"></div>
    <div class="bot">
      <div class="yixuan">{{ names == '未结' ? '已选：' : '共计：' }} <span style="color: red;">{{ num }}</span> 台 <span
          style="color: red;margin-left: 10px;">￥{{ price ? price.toFixed(2) : '0.00' }}</span></div>
      <div v-if="names == '未结'" style="position: relative;">
        <van-checkbox @click.stop class="hbs" v-model="checkedhb" shape="square">
          <span style="color: #fff;">合并已有账单</span>

        </van-checkbox>
        <van-button type="danger" @click="toyulan">
          <span>生成账单</span>

        </van-button>
      </div>
    </div>
    <van-popup v-model="timeshow" position="bottom" :style="{ height: '45%' }">
      <van-datetime-picker @cancel="timeshow = false" @confirm="settiem" v-model="currentDate" type="year-month"
        title="选择年月" :min-date="minDate" :max-date="maxDate" :formatter="formatter" />

    </van-popup>
    <van-popup v-model="tdshow" class="xgtdiv" :style="{ width: '60%' }">
      <div style="text-align: center;font-size: 16px;font-weight: bold;">{{ sjform.carNo }} 返点设置</div>
      <div><van-radio-group style="font-size: 14px;" v-model="sjform.fdType">
          <div class="tddvv">
            <van-radio :name="0">自动</van-radio>
          </div>

          <div class="tddvv" style="display: flex;align-items: center;">
            <van-radio :name="1">手动</van-radio> <input class="inpsd" type="text" v-model="sjform.fd"><span>元</span>
          </div>
        </van-radio-group>
      </div>
      <div style="display: flex;justify-content: space-between;margin-top: 25px;"><van-button @click="tdshow = false"
          type="danger" size="small">取消</van-button><van-button @click="serzsbind" type="primary"
          size="small">确定</van-button>
      </div>
    </van-popup>
  </div>
</template>
  
<script>
import { sourceOrder, postcbbpay, dltquote, sourceaccount, sourceaccounthehbing, insurancefd } from "@/api/check";
import userModel from "@/api/user";
import moment from "moment";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  data() {
    return {
      // , "余额充值"
      list: [], //
      carlist: [],
      tdshow: false,
      sjform: {},
      num: 0,
      radios: '1',
      price: 0,
      gid: this.$route.query.gid,
      source: this.$route.query.source,
      acv: this.$route.query.acv,
      names: this.$route.query.names,
      sellsMan: this.$route.query.sellsMan,
      type: this.$route.query.type,
      settle: this.$route.query.settle,
      carNo: this.$route.query.carNo,
      acvred: null,
      phone: "",
      amount: "",
      key: "",
      remark: "",
      timeshow: false,
      checkedhb: false,
      checked: false,
      checkedqx: false,
      payType: 2,
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(2030, 10, 1),
      status: null, //工单计收还是预付款
      yfkisok: false,
      form: {},
      show: false,
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")) || {},
      user: JSON.parse(localStorage.getItem("userInfo")),
      page: 0,
      size: 40,
      typeA: this.$route.query.typeA,
      typeB: this.$route.query.typeB,
      loading: false,
      total: 0,
      finished: false,
      isiOS: false,
      currentDate: new Date(),
      time: moment().format("YYYY-MM"),
    };
  },
  watch: {},
  methods: {
    zsclick(item) {
      if (item.fdType == null) {
        item.fdType = 0
      }
      this.sjform = JSON.parse(JSON.stringify(item));
      this.tdshow = true
    },
    serzsbind() {
      var data = {
        calculate: this.sjform.fdType,
        oid: this.sjform.oid,
        fd: this.sjform.fd,
      }
      insurancefd(data).then((res) => {
        if (res.code == 200) {
          // this.$toast.success("生成账单成功");
          this.page = 0;
          this.list = [];
          this.getlist()
          this.tdshow = false
        }
      });
    },
    tozhichi(item) {
      location.href =
        "http://customer.car-posthouse.cn/spare_king/expenditure.html?gid=" + this.gid + "&oid=" + item.oid + "&amount=" + item.fd + "&source=" + item.source + "&carNo=" + item.carNo + "&fdrate=" + item.fdrate + "&yw=" + 'true';
    },
    toxqs(item) {
      this.$router.push({
        path: "/bill_details",
        query: {
          gid: this.gid,
          source: this.source,
          accountNumber: item.accountNumber,
        },
      });
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    toapp(item) {
      // var oid = 255491
      var _this = this
      var map = {
        'oid': item.oid, //0:项目，1:支付方式
      }
      let arr = JSON.stringify(map)
      if (this.names == '已结') {//工单已结算
        window.androidjs.onCallbackIntent("PaidWorkOrderActivity", arr, false);
      } else {
        window.androidjs.onCallbackIntent("MaintenanceOrderActivity", arr, false);
      }
    },
    shuaxin() {
      this.$router.go(0)
    },
    //全选
    onSearchqx(e) {
      console.log(e);
      if (e) {
        for (var i in this.list) {
          if (!this.list[i].accountNumber && this.list[i].fdresult == 0) {
            this.list[i].checked = true;
          }
        }
      } else {
        for (var i in this.list) {
          if (!this.list[i].accountNumber) {
            this.list[i].checked = false;
          }
        }
      }
      this.heji();
    },
    changexuanze() {
      this.heji();
    },
    heji() {
      var num = 0;
      var price = 0;
      for (var i in this.list) {
        if (this.list[i].checked) {
          num += 1;
          if (this.list[i].accident) {
            price -= this.list[i].fd;
          } else {
            price += this.list[i].amount;
          }

        }
      }
      this.num = num;
      this.price = price;
    },
    //根据车牌查询未结算信息
    tourl(item) {
      this.$router.push({
        path: "offer_details",
        query: {
          id: item.id,
          gid: this.gid,
        },
      });
    },
    toyulan() {
      var _this = this
      var source = [];
      var yue = []
      for (var i in this.list) {
        if (this.list[i].checked) {
          var ris = {
            gid: this.gid,
            source: this.source,
            oid: this.list[i].oid,
            carNo: this.list[i].carNo,
            status: 0,
            sellsMan: this.sellsMan,
            title: null,
            type: this.list[i].type
          }
          yue.push(moment(this.list[i].createdDate).format("MM"))
          source.push(ris);
        }
      }
      if (source.length == 0) {
        this.$toast("请先选择再预览");
        return;
      }
      var min = Math.min(...yue)
      var max = Math.max(...yue)

      if (source.length == 1) {
        var title = source[0].carNo + ' 账单'
      } else if (source.length == this.total) {
        if (min == max) {
          var title = max + ' 月份账单 全部车辆'
        } else {
          var title = min + '-' + max + ' 月份账单 全部车辆'
        }

      } else {
        if (min == max) {
          var title = max + ' 月份账单 部分车辆'
        } else {
          var title = min + '-' + max + ' 月份账单 部分车辆'
        }
      }
      source[0].title = title
      console.log(source)
      // return
      if (this.checkedhb) {
        sourceaccounthehbing(source, 1).then((res) => {
          if (res.code == 200) {
            this.$toast.success("生成账单成功");
            setTimeout(() => {
              _this.$router.push({
                path: "/bill_details",
                query: {
                  gid: this.gid,
                  source: this.source,
                  accountNumber: res.data,
                },
              });
            }, 500);

          }
        });
      } else {
        sourceaccounthehbing(source, 0).then((res) => {
          if (res.code == 200) {
            this.$toast.success("生成账单成功");
            setTimeout(() => {
              _this.$router.push({
                path: "/bill_details",
                query: {
                  gid: this.gid,
                  source: this.source,
                  accountNumber: res.data,
                },
              });
            }, 500);

          }
        });
      }


    },
    tourss(item) {
      location.href =
        "http://customer.car-posthouse.cn/reconciliation.html?gid=" +
        this.gid +
        "&ids=" +
        item.oid;
    },

    timeshang() {
      this.time = moment(this.time)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM");
      this.onSearch();
    },
    timexia() {
      this.time = moment(this.time)
        .startOf("month")
        .subtract("months", -1)
        .format("YYYY-MM");
      this.onSearch();
    },
    settiem(e) {
      this.date = moment(e).format("YYYY-MM");
      console.log(moment(e).format("YYYY-MM"));
      this.page = 0;
      this.list = [];
      this.getlist();
      this.timeshow = false;
    },
    tourls() {
      this.$router.push({
        path: "/offer",
        query: {
          gid: this.gid,
        },
      });
    },
    //删除
    dlt(item, idx) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否确认删除该报价单？",
        })
        .then(() => {
          dltquote({ id: item.id }).then((res) => {
            if (res.code == 200) {
              this.list.splice(idx, 1);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },

    onSearch() {
      this.page = 0;
      this.num = 0;
      this.price = 0;
      // this.checkedqx = false;
      this.list = [];
      this.getlist();
    },
    // 计算天数
    DateDiff(sDate1, sDate2) {
      //sDate1和sDate2是yyyy-MM-dd格式
      var aDate, oDate1, oDate2, iDays;
      aDate = sDate1.split("-");
      oDate1 = new Date(aDate[1] + "-" + aDate[2] + "-" + aDate[0]); //转换为yyyy-MM-dd格式
      aDate = sDate2.split("-");
      oDate2 = new Date(aDate[1] + "-" + aDate[2] + "-" + aDate[0]);
      iDays = Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24; //把相差的毫秒数转换为天数
      iDays = iDays.toFixed(0); //天数保留两位小数
      return iDays;
    },

    getlist() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      var dayType = 0;
      if (this.typeA && this.typeB) {
        dayType = 0
      } else if (this.typeA) {
        dayType = 1
      } else {
        dayType = 2
      }
      var data = {
        gid: this.gid,
        source: this.type == 0 ? this.source : '',
        type: this.type,
        pay: this.acv,
        page: this.page,
        dayType: dayType,
        day: 3,
        size: 200,
        time: "",
      };
      sourceOrder(data).then((e) => {
        loading.clear();
        console.log(e);
        if (e.code == 200) {
          let rows = e.data.content;
          for (var i in rows) {
            rows[i].checked = false;
            if (rows[i].factoryOut) {
              rows[i].days = this.DateDiff(moment().format("YYYY-MM-DD"), moment(rows[i].factoryOut).format("YYYY-MM-DD"))
            }
          }
          console.log(rows)
          this.loading = false;
          this.total = e.data.totalElements;

          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          } else {
            this.finished = false;
          }
          // 将新数据与老数据进行合并
          this.list = this.list.concat(rows);
          if (this.names == "已结") {
            var num = 0;
            var price = 0;
            for (var i in this.list) {
              num += 1;
              price += this.list[i].amount;
            }
            this.num = num;
            this.price = price;
          }
          if (this.settle == 'true') {
            for (var i in this.list) {
              if (!this.list[i].accountNumber && this.list[i].carNo == this.carNo) {
                this.list[i].checked = true;
              }
            }
            this.heji();
          } else {
            if (this.typeA || this.typeB) {
              for (var i in this.list) {
                if (!this.list[i].accountNumber && this.list[i].fdresult == 0) {
                  this.list[i].checked = true;
                }
              }
              this.heji();
            }
          }



          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.list.length >= this.total) {
            this.finished = true;
          }
        }
      });
    },
    onLoads() {
      this.page++;
      this.getlist();
    },


    back() {
      this.$router.go(-1);
    },
    setupWebViewJavascriptBridge(callback) {
      if (window.WebViewJavascriptBridge) {
        return callback(window.WebViewJavascriptBridge);
      }
      if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback);
      }
      window.WVJBCallbacks = [callback];
      let WVJBIframe = document.createElement("iframe");
      WVJBIframe.style.display = "none";
      WVJBIframe.src = "https://__bridge_loaded__";
      document.documentElement.appendChild(WVJBIframe);
      setTimeout(() => {
        document.documentElement.removeChild(WVJBIframe);
      }, 0);
    },
    //
    appSource() {
      var _this = this;
      const u = navigator.userAgent,
        app = navigator.appVersion;
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (this.isiOS) {
        this.isok = false;
        this.setupWebViewJavascriptBridge((bridge) => {
          var token = bridge.callHandler(
            "getToken",
            null,
            function responseCallback(responseData) {
              localStorage.setItem("Authorization", responseData);
              _this.token = responseData;
              // alert('走了这里' +  _this.token)
              _this.getlist();

              return responseData;
            }
          );
        });
        console.log("ios");
      } else {
        this.isok = true;
        _this.token = window.androidjs.getLocalData("getToken");
        // _this.token = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNDk3IiwiZXhwIjoxNzM2MzQ0OTU2fQ.vjAPowX_nV1Vi6dcY2PlaW6uPtNk5EIbFRXFqWdExRVCdGW6f3lc6nXd3BJFNhAiizuPgcxSTp4XwHbw30D80g";
        localStorage.setItem("Authorization", _this.token);
        // alert(_this.token)
        _this.getlist();
        console.log("andriod");
      }
    },
  },
  created() {
    // this.getlist();
    //判断是否在微信环境
    this.appSource();
  },
  mounted() { },
  computed: {},
};
</script>
<style>
body {
  background: #f6f6f6;
}
</style>
<style lang="less" scoped>

.nav-bar {
  position: sticky;
  top: 46px;
  left: 0;
  width: 100%;
  // height: 40px;
  background: #fff;

  line-height: 40px;
  font-size: 16px;
  z-index: 99;
}

.zdbj {
  background: #007832;
  color: #fff;
  padding: 0 6px;
  border-radius: 4px;
  font-size: 13px;
}

.xgtdiv {
  padding: 10px 20px;

  .tddvv {
    margin-top: 10px;
  }

  .inpsd {
    width: 60px;
    border: 1px solid #dcdcdc;
    padding: 0 10px;
    margin-left: 15px;
    height: 24px;
  }

  .van-button {
    width: 40%;
  }
}

.hbs {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  color: #fff;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 13px;
}

.carNo {
  color: #fff;
  font-family: JDZhengHT-Regular;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  letter-spacing: 0;
  text-align: center;
}

.flxj {
  display: inline-block;
  background: #f5f5f5;
  color: #333;
  line-height: 20px;
  padding: 1px 4px;
  border-radius: 3px;
}

.flisx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ywdiv {
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 12px;
  justify-content: space-between;
  font-size: 14px;
  min-height: 30px;
}

.flx {
  display: flex;
  align-items: center;
}



.wj {
  background: rgb(255, 8, 8);
}

.yj {
  background: #007832;
}

.jsname {
  color: #fff;
  padding: 1px 6px;
  border-radius: 2px;
  font-size: 14px;
  display: inline-block;
  line-height: 16px;
}

.gz {
  background: rgb(171, 94, 0);
  color: #fff;
  padding: 1px 6px;
  border-radius: 2px;
  font-size: 14px;
}

.time {
  padding: 6px 12px;
  font-size: 16px;
  font-weight: bold;
}

.xzs {
  border: 1px solid #007832 !important;
  margin-right: 5px;
}

.mian {
  margin: 0 12px;
  border-top: 1px solid #f5f5f5;
  border-radius: 6px;
  margin-top: 10px;
  padding: 10px 0;
  background: #fff;
  display: flex;
  align-items: center;

  .fximg {
    width: 24px;
    margin-left: 22px;
  }

  .sc {
    width: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .zuolit {
    flex: 1;
    border-right: 1px dashed #dcdcdc;
  }

  .xmdiv {
    line-height: 30px;

    font-size: 14px;
    padding: 0 12px;
  }
  .sxtc{
    line-height: 20px;
    .span{
      color: rgb(3, 138, 196);
    }
  }

  .ris {
    text-align: right;
  }

  .tjdiv {
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 40px;
    background: #fff;
    // line-height: 40px;
    padding: 10px 0;
    border-top: 1px solid #f4f4f4;
  }
}

.bot {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;

  .yixuan {
    // width: 70%;
    display: inline-block;
    line-height: 25px;
    background: #e4e4e4;
    margin: auto;
    text-align: center;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 0 15px;
    font-size: 14px;
  }

  .van-button {
    width: 100%;
    margin: auto;
    display: block;
    font-size: 15px;
    height: 42px;
  }
}


</style>

  
  